<template>
    <v-card tile max-height="400" >
        <v-list width="310" dense>
            <v-list-item class="fixed">
                <v-list-item-subtitle class="poppins text-uppercase fw500 f11">Notifications</v-list-item-subtitle>
                <v-btn dense text small class="poppins secondary-2--text f11" @click="$emit('markAll')"> Mark all as read </v-btn>
            </v-list-item>
            <v-divider></v-divider>
            <v-container v-if="notification.length === 0" class="secondary-2--text f12 text-center">
                You have no notifications
            </v-container>
            <section v-else >
                <v-list-item v-for="(item , i) in notification" :key="i" :class="!item.read_at && 'primary-1'" @click="$emit('readMessage', item)">
                    <v-list-item-avatar v-if="item.data.photo" :color="item.type.split('\\')[2] === 'GroupMessageNotification' ? 'orange lighten-5' : ''">
                        <v-icon v-if="item.type.split('\\')[2] === 'GroupMessageNotification'" color="orange lighten-2">mdi-account-group-outline</v-icon>
                        <v-img v-else :src="item.data.photo"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content class="py-2" v-if="item.type.split('\\')[2] === 'AnnouncementNotification'">
                        <span class="f12">
                            <b>{{ item.data.name }}</b> {{ item.data.text }} <b>{{ item.data.course }}</b>
                        </span>
                        <v-list-item-subtitle class="f10">{{ $dateFormat.fromNow(item.created_at) }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content class="py-2" v-if="item.type.split('\\')[2] === 'PrivateMessageNotification'">
                        <span class="f12">
                            <b>{{ item.data.name }}</b> {{ item.data.text }}
                        </span>
                        <v-list-item-subtitle class="f10">{{ $dateFormat.fromNow(item.created_at) }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content class="py-2" v-if="item.type.split('\\')[2] === 'AnnouncementCommentNotification'">
                        <span class="f12" v-if="role === 'user'">
                            <b>{{ item.data.name }}</b> replied to your comment in <b> {{ item.data.course }} </b>
                        </span>
                        <span class="f12" v-if="role === 'instructor'">
                            <b>{{ item.data.name }}</b> commented to your announcement on <b> {{ item.data.course }} </b>
                        </span>
                        <v-list-item-subtitle class="f10">{{ $dateFormat.fromNow(item.created_at) }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content class="py-2" v-if="item.type.split('\\')[2] === 'GroupMessageNotification'">
                        <span class="f12" v-if="item.data.synced_users">
                            <b>{{ item.data.name }}</b> added you to their group <b> {{ item.data.group_name }} </b>
                        </span>
                        <span class="f12" v-else>
                            <b>{{ item.data.name }}</b> sent a message in <b> {{ item.data.group_name }} </b>
                        </span>
                        <v-list-item-subtitle class="f10">{{ $dateFormat.fromNow(item.created_at) }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content class="py-2" v-if="item.type.split('\\')[2] === 'ShareAssessmentNotification'">
                        <span class="f12">
                            <b>{{ item.data.name }}</b> {{ item.data.text }}
                            <section class="d-flex align-center">
                                <v-icon size="20" color="primary-1">mdi-file-outline</v-icon> <b> {{ item.data.assessment }}</b>
                            </section>
                        </span>
                        <v-list-item-subtitle class="f10">{{ $dateFormat.fromNow(item.created_at) }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content class="py-2" v-if="item.type.split('\\')[2] === 'OpenCourseNotification'">
                        <span class="f12">
                            <b>{{ item.data.name }}</b> {{ item.data.text }} <b>{{ item.data.course }}</b>
                        </span>
                        <v-list-item-subtitle class="f10">{{ $dateFormat.fromNow(item.created_at) }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content class="py-2" v-if="item.type.split('\\')[2] === 'AssessmentDueNotification'">
                        <span class="f12">
                            {{ item.data.text }} <b>{{ `${item.data.course} (${item.data.assessment})` }}</b>.
                        </span>
                        <v-list-item-subtitle class="f10">{{ $dateFormat.fromNow(item.created_at) }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </section>
        </v-list>
    </v-card>
</template>

<script>
export default {
    props: ["notification", "role"],
}

</script>